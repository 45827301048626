import React from 'react';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="w-full"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1 className="c-h1">Latest Stories</h1>
        </div>
        <section className="section flex px-8">
          <BlogRoll />
        </section>
      </Layout>
    );
  }
}
