import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const BlogRoll = props => {
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="flex-1">
      {posts &&
        posts.map(({ node: post }) => (
          <div
            className="flex flex-col w-full justify-center max-w-5xl mx-auto my-8"
            key={post.id}
          >
            <article
              className={`grid grid-cols-3 grid-rows-1 gap-4 mb-8 bg-orange-500 ${
                post.frontmatter.featuredpost ? 'is-featured' : ''
              }`}
            >
              {post.frontmatter.featuredimage ? (
                <div className="col-span-1 self-stretch">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                    }}
                  />
                </div>
              ) : null}
              <div className="col-start-2 col-span-2 row-start-1 flex flex-col">
                <header className="my-4">
                  <div className="post-meta">
                    <Link to={post.fields.slug}>
                      <h2 className="c-h2 font-bold text-2xl hover:underline">
                        {post.frontmatter.title}
                      </h2>
                    </Link>
                  </div>
                </header>
                <p>
                  {post.excerpt}
                  <br />
                  <br />
                  <Link
                    className="px-4 py-2 bg-blue-400 hover:bg-white text-white hover:text-blue-400"
                    to={post.fields.slug}
                  >
                    Keep Reading →
                  </Link>
                </p>
              </div>
            </article>
          </div>
        ))}
    </div>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
